import React from 'react';
import ContentBlock from './ContentBlock';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import Icon from './Icon';



import one from '../../img/horrormax-banner-american-scream-king.jpg'
import two from '../../img/horrormax-banner-a-wich-story.jpg'
import three from '../../img/horrormax-banner-carnivale-creepshow.jpg'
import four from '../../img/horrormax-banner-death-factory.jpg'
import five from '../../img/horrormax-banner-eternal-damn-nation.jpg'
import six from '../../img/horrormax-banner-grave-caller.jpg'
import seven from '../../img/horrormax-banner-ice-cream-man.jpg'
import eight from '../../img/horrormax-banner-on-the-brain.jpg'
import nine from '../../img/horrormax-banner-skyquake.jpg'
import ten from '../../img/horrormax-banner-teenage-ghost-punk.jpg'
import eleven from '../../img/horrormax-banner-the-night-he-came-home.jpg'
import twelve from '../../img/horrormax-banner-the-night-of-living-dead-in-color.jpg'
import thirteen from '../../img/horrormax-banner-vampegeddon.jpg'
import fourteen from '../../img/horrormax-banner-world-w4r.jpg'


const content = [one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen]

/**
 * @function ContentRow
 */
const ContentRow = () => {
    // const getPos = useCallback(e => {
    //     const pos = e.target.parentElement.getBoundingClientRect()
    //     setActive({ category, pos })
    // }, [])

    return <div className="content-row"
        css={css`
        padding-left: 60px;
        overflow-x: hidden;

    .ContentBlock {
        //   flex: 18vw;
        width: 240px;
          flex-shrink: 0;
          height: 140px;
          margin-right: 15px;
        }
    `}>
        <div css={css`
            h2{
                margin:20px 0 10px;
                color:white;
            }

            .block-wrapper {
                display: flex;
                width:100%;
                position: relative;
            }
        `}>
            <h2>Trending Now</h2>
            <div className="block-wrapper">
            {content.map(img => (
                <ContentBlock key={img} img={img}>
                    <Icon type="play" />
                    {/* <Icon type="info-circle" onClick={getPos} /> */}
                </ContentBlock>
            ))}
        </div>
        </div>
        
    </div>
}

export default ContentRow