import React from "react";
import { Link } from "react-router-dom";

import Helper from "../Helper/helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { translate } from "react-multi-lang";

//check mobile Devices
import MediaQuery from "react-responsive";

class HomePageBanner extends Helper {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    // this.inputRef.current is null here
  }
  state = {
    wishlistApiCall: false,
    wishlistResponse: null,
    inputData: {},
    redirect: false,
    redirectPPV: false,
    redirectPaymentOption: false,
    videoDetailsFirst: null,
    playButtonClicked: false,
    wishlistStatusCheck: 0,
    isMuted: true,
  };
  componentDidMount() {
    this.setState({ playButtonClicked: false });
    let wishlistStatusCheck = 0;
    if (this.props.banner.wishlist_status === 1) {
      wishlistStatusCheck = 1;
    } else {
      wishlistStatusCheck = 0;
    }
    this.setState({ wishlistStatusCheck });
  }
  handleWishList = (event, admin_video_id) => {
    event.preventDefault();
    let inputData = {
      ...this.state.inputData,
      admin_video_id: admin_video_id,
    };

    api.postMethod("wishlists/operations", inputData).then((response) => {
      if (response.data.success === true) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({
          wishlistResponse: response.data,
          wishlistApiCall: true,
        });
        if (response.data.wishlist_id != null) {
          this.setState({
            wishlistStatusCheck: 1,
          });
        } else {
          this.setState({
            wishlistStatusCheck: 0,
          });
        }
      } else {
        ToastDemo(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  };

  handlePlayVideo = async (event, admin_video_id) => {
    event.preventDefault();

    this.setState({ playButtonClicked: true });
    let inputData = {
      ...this.state.inputData,
      admin_video_id: admin_video_id,
    };

    await this.onlySingleVideoFirst(inputData);

    this.redirectStatus(this.state.videoDetailsFirst);
  };

  getTitle(title) {
    return title.replace(/ *\([^)]*\) */g, "");
  }

  handleMuteVideo = (event) => {
    event.preventDefault();

    this.inputRef.current.muted = !this.inputRef.current.muted;

    this.setState({ isMuted: this.inputRef.current.muted });
  };

  render() {
    const { banner } = this.props;

    if (this.state.playButtonClicked) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    const { isMuted } = this.state;
    // console.log(banner);
    return (
      <div className="featured">
        {banner.data.map((video) => (
          <>
            <div className="video-overlay">
              <video muted ref={this.inputRef} autoPlay loop>
                <source src={video.trailer_video} type="video/mp4" />
              </video>
            </div>
            <div className="info">
              <img className="" srcSet={video.logo} alt="" />
              <span className="desc">{video.description}</span>
              <div className="buttons">
                <MediaQuery minDeviceWidth={1224}>
                  <Link to="/login" className="btn btn-danger login_btn_main">
                    <i />
                    Login
                  </Link>
                  <Link
                    to="/subscribe-with-Stripe"
                    className="btn btn-danger login_btn_main"
                  >
                    {<div>Subscribe</div>}
                  </Link>
                </MediaQuery>
                <MediaQuery minDeviceWidth={1224} deviceWidth={800}>
                  <Link
                    to="/login"
                    className="btn btn-danger login_btn_main_nav"
                  >
                    <i />
                    Login
                  </Link>
                  <Link
                    to="/subscribe-with-Stripe"
                    className="btn btn-danger login_btn_main_nav"
                  >
                    {<div>Subscribe</div>}
                  </Link>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1224}>
                  <Link
                    to="/login"
                    className="btn btn-danger login_btn_main_nav"
                  >
                    <i />
                    Login
                  </Link>
                  <Link
                    to="/subscribe-with-Stripe"
                    className="btn btn-danger login_btn_main_nav"
                  >
                    {<div>Subscribe</div>}
                  </Link>
                </MediaQuery>

                <Link
                  to="#"
                  className="btn b_btn"
                  onClick={(event) => this.handleMuteVideo(event)}
                >
                  {isMuted === true ? (
                    <div>
                      <i
                        className=""
                        style={{
                          display: "none",
                        }}
                      />
                      <img
                        alt=""
                        src={window.location.origin + "/images/off.png"}
                        className="mr-2 banner-wishlist-icon banner-wishlist-icon-main b_img"
                      />
                    </div>
                  ) : (
                    <div>
                      <i
                        className=""
                        style={{
                          display: "none",
                        }}
                      ></i>
                      <img
                        alt=""
                        src={window.location.origin + "/images/on.png"}
                        className="mr-2 banner-wishlist-icon b_img"
                      />
                    </div>
                  )}
                </Link>
              </div>
            </div>
          </>
        ))}
      </div>
    );
  }
}

export default withToastManager(translate(HomePageBanner));
