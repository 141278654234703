import React from 'react';
import IconArrowDown from './../Icons/IconArrowDown'
import './SlideButton.scss'
  
const SlideButton = ({ onClick, type }) => (
  <button className={`slide-button g-sliderbtn slide-button--${type}` } onClick={onClick}>
    <span>
      <IconArrowDown />
    </span>
  </button>
);

export default SlideButton;