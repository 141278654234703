import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Helper from "../../Helper/helper";
import ContentLoader from "../../Static/contentLoader";

import api from "../../../Environment";

// import io from "socket.io-client";

import { apiConstants } from "../../Constant/constants";

// const socket = apiConstants.socketUrl
//   ? io(apiConstants.socketUrl, {
//       secure: true,
//       reconnect: true,
//       rejectUnauthorized: false,
//     })
//   : "";
let userId = localStorage.getItem("userId");

let accessToken = localStorage.getItem("accessToken");

class VideoComponent extends Helper {
  state = {
    loadingFirst: true,
    videoDetailsFirst: null,
    onPlayStarted: false,
    videoList: {},
    videoData: null,
    videoId: 0,
    socket: false,
    query: "",
    onSeekPlay: true,
    socketConnection: true,
    videoDuration: 0,
    socketConnected: false,
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ loadingFirst: false });
    } else {
      window.location = "/home";
    }
  }

  // timer = async () => {
  //   if (this.state.onPlayStarted) {
  //     await this.socketConnectionfun(userId, accessToken);
  //   }
  // };

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    let videoId = this.props.location.state.videoDetailsFirst.admin_video_id;
    let videoData = [
      {
        sub_profile_id: localStorage.getItem("active_profile_id"),
        admin_video_id: videoId,
        id: userId,
        token: accessToken,
        duration: this.state.videoDuration,
      },
    ];

    api
      .postMethod("save/watching/video", videoData[0])
      .then((response) => {
        console.log(response);
        if (response.data.success === true) {
        } else {
        }
      })
      .catch(function(error) {});

    clearInterval(this.state.intervalId);
  }

  onCompleteVideo = () => {
    this.addHistory(this.props.location.state.videoDetailsFirst.admin_video_id);
    this.setState({ onPlayStarted: false, socketConnection: false });
    // if (this.state.socketConnected) {
    //   socket.emit("disconnect");
    // }
  };

  onVideoPlay = async () => {
    let intervalId = setInterval(this.timer, 3000);

    this.setState({ intervalId: intervalId });

    this.setState({ onPlayStarted: true, socketConnection: true });

    let inputData = {
      admin_video_id: this.props.location.state.videoDetailsFirst
        .admin_video_id,
    };
    await this.onlySingleVideoFirst(inputData);

    this.redirectStatus(this.state.videoDetailsFirst);

    const seekTime = this.state.videoDetailsFirst.seek_time_in_seconds
      ? this.state.videoDetailsFirst.seek_time_in_seconds
      : 0;

    console.log(seekTime);

    if (this.state.onSeekPlay) {
      this.player.seekTo(parseFloat(seekTime));
    }

    this.setState({ onSeekPlay: false });
  };

  addHistory = (admin_video_id) => {
    // api
    //   .postMethod("addHistory", { admin_video_id: admin_video_id })
    //   .then((response) => {
    //     if (response.data.success === true) {
    //     } else {
    //     }
    //   })
    //   .catch(function (error) {});
  };

  // socketConnectionfun = (userId, accessToken) => {
  //   if (apiConstants.socketUrl) {
  //     console.log("Dandash");
  //     let videoId = this.props.location.state.videoDetailsFirst.admin_video_id;

  //     socket.on("connect", function () {
  //       // let query = `user_id=` + userId + `&video_id=` + videoId;
  //     });

  //     socket.on("connected", function () {
  //       this.setState({ socketConnected: true });
  //     });

  //     socket.on("disconnect", function () {
  //       this.setState({ socketConnected: false });
  //     });

  //     console.log(this.state.videoDuration);

  //     let videoData = [
  //       {
  //         sub_profile_id: "",
  //         admin_video_id: videoId,
  //         id: userId,
  //         token: accessToken,
  //         duration: this.state.videoDuration,
  //       },
  //     ];
  //     // Remove this call
  //     // var result = socket.emit("save_continue_watching_video", videoData[0]);
  //     // console.log(result);
  //   }
  // };

  onPauseVideo = async () => {
    console.log("onPause");
    // if (this.state.socketConnected) {
    //   socket.emit("disconnect");
    // }
    clearInterval(this.state.intervalId);
  };

  onVideoTimeUpdate = (duration) => {
    console.log(duration.target.currentTime);
    let video_duration = duration.target.currentTime;

    let sec = parseInt(video_duration % 60);
    let min = parseInt((video_duration / 60) % 60);
    let hours = parseInt(video_duration / 3600);

    if (hours > 1) {
      this.setState({ videoDuration: hours + ":" + min + ":" + sec });
    } else {
      this.setState({ videoDuration: min + ":" + sec });
    }
  };

  ref = (player) => {
    this.player = player;
  };

  convert(srtSubtitle) {
    var webvtt;
    if (srtSubtitle !== undefined) {
      webvtt = srtSubtitle.substr(0, srtSubtitle.lastIndexOf(".")) + ".vtt";
    } else {
      webvtt = srtSubtitle;
    }
    return webvtt;
  }

  render() {
    const pageType = "videoPage";
    if (this.state.onPlayStarted) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst,
        pageType
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    const { loadingFirst } = this.state;
    let mainVideo, videoTitle, videoType, subTitle;

    if (loadingFirst) {
      return <ContentLoader />;
    } else {
      // Check the whether we need to play the trailer or main video

      if (this.props.location.state.videoFrom !== undefined) {
        subTitle = this.convert(
          this.props.location.state.videoDetailsFirst.video_subtitle
        );

        if (this.props.location.state.videoFrom === "trailer") {
          mainVideo = this.props.location.state.videoDetailsFirst.resolutions
            .original;
          if (
            this.props.location.state.videoDetailsFirst.trailer_subtitle !==
            undefined
          ) {
            subTitle = this.convert(
              this.props.location.state.videoDetailsFirst.trailer_subtitle
            );
          }
        } else {
          mainVideo = this.props.location.state.videoDetailsFirst.resolutions
            .original;
        }

        videoTitle = this.props.location.state.videoDetailsFirst.name;

        videoType = this.props.location.state.videoDetailsFirst.video_type;
      } else {
        mainVideo = this.props.location.state.videoDetailsFirst.main_video;

        subTitle = this.convert(
          this.props.location.state.videoDetailsFirst.video_subtitle
        );

        videoTitle = this.props.location.state.videoDetailsFirst.title;

        videoType = this.props.location.state.videoDetailsFirst.video_type;
      }

      return (
        <div>
          <div className="single-video">
            <ReactPlayer
              ref={this.ref}
              url={mainVideo}
              controls={true}
              width="100%"
              height="100vh"
              playing={true}
              onStart={this.onLoad}
              onPause={this.onPauseVideo}
              onPlay={
                this.props.location.state.videoFrom === "trailer"
                  ? ""
                  : this.onVideoPlay
              }
              onEnded={this.onCompleteVideo}
              onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
              config={{
                file: {
                  tracks: [
                    {
                      kind: "subtitles",
                      src: subTitle,
                      srcLang: "en",
                      default: true,
                    },
                  ],
                  attributes: {
                    crossOrigin: "true",
                    controlsList: "nodownload",
                  },
                },
              }}
            />
            <div className="back-arrowsec">
              <Link
                to={{
                  pathname: `/detail/${this.props.location.state.videoDetailsFirst.admin_video_id}`,
                  movie: this.props.location.state.videoDetailsFirst,
                }}
              >
                <img
                  src={window.location.origin + "/assets/img/left-arrow.png"}
                  alt="arrow"
                />
                {videoType === 2 ? (
                  ""
                ) : (
                  <span className="txt-overflow capitalize ml-3">
                    {videoTitle}
                  </span>
                )}
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default VideoComponent;
