import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import SliderContext from "./context";
import Mark from "./Mark";
import "./Item.scss";
import Helper from "../../Helper/helper";
import MediaQuery from 'react-responsive';

class Item extends Helper {
    state = {
        loadingFirst: true,
        videoDetailsFirst: null,
        redirect: false,
        redirectPPV: false,
        redirectPaymentOption: false,
        playButtonClicked: false
    };

    componentDidMount() {
        this.setState({ playButtonClicked: false });
    }

    handlePlayVideo = async event => {
        event.preventDefault();

        const inputData = {
            admin_video_id: this.props.movie.admin_video_id,
            skip: 0
        };

        await this.onlySingleVideoFirst(inputData);

        this.redirectStatus(this.state.videoDetailsFirst);
        this.setState({ playButtonClicked: true });
    };

     getTitle(title) {
        return title.replace(/ *\([^)]*\) */g, "");
    };

     getFormatedDuration(duration) {     
    const hours = duration.split(':')[0];
    const minutes = duration.split(':')[1];
    const du = hours + ' hr ' + minutes + ' m ';
    return du;
  };
  getYear(year) {
    let ye = "";
    if (year.includes("(")) {
      const regExp = /\([^)]+\)/;
      ye = regExp.exec(year).toString();
      ye = ye.replace('(', "");
      ye = ye.replace(')', " ");
    }
    
    return ye;
  };
    render() {
        const { movie } = this.props;

        if (this.state.playButtonClicked) {
            const returnToVideo = this.renderRedirectPage(
                this.state.videoDetailsFirst
            );

            if (returnToVideo != null) {
                return returnToVideo;
            }
        }

        return (
            <SliderContext.Consumer>
                {({ onSelectSlide, currentSlide, elementRef }) => {
                    const isActive =
                        currentSlide &&
                        currentSlide.admin_video_id === movie.admin_video_id;

                    return (
                    <React.Fragment>
                        <MediaQuery minDeviceWidth={1224}>
                                <div
                                ref={elementRef}
                                className={cx(" item5 sliderthumb-text g-item", {
                                    "item--open": isActive
                                })}
                                style={{
                                    backgroundImage:
                                        "url(" + movie.default_image + ")"
                                }}
                            >
                            
                                <div className="g-container width-100 slider-content-box g-slider">

                                    <h4 className="g-thumb-title">{this.getTitle(movie.title)}</h4>   
                                    <div  className="g-contents" style={{backgroundImage:"url(" + movie.default_image + ")"}}>
                                        <div className="container item_container g-play">
                                        <Link to="/subscribe-with-Stripe">
                                        <div className="thumb-playicon g-playicon">
                                            <i className="fas fa-play g-fa-play" />
                                        </div>
                                    </Link>
                                    </div>
                                    </div>
                                                
                                                            
                                    
                                    <div className="container g-discription">
                                        <div className="row">
                                            <div className="thumb-desc "> 
                                                {this.getYear(movie.title)}{" | "}{this.getFormatedDuration(movie.duration)}
                                            </div>
                                            <span className="small yellow-clr g-star">
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="far fa-star" />
                                                <i className="far fa-star" />
                                            </span>
                                        </div> 
                                </div>
                                
                            
                                
                                    
                            
                                        
                                {isActive && <Mark />}

                                </div>

                                
                            </div>
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={1224}>
                                <div
                                ref={elementRef}
                                className={cx(" item5 sliderthumb-text g-item c-g-item", {
                                    "item--open": isActive
                                })}
                                style={{
                                    backgroundImage:
                                        "url(" + movie.default_image + ")"
                                }}
                            >
                            
                                <div className="g-container width-100 slider-content-box g-slider">

                                    <h4 className="g-thumb-title">{this.getTitle(movie.title)}</h4>   
                                    <div  className="g-contents" style={{backgroundImage:"url(" + movie.default_image + ")"}}>
                                        <div className="container item_container g-play">
                                        <Link to="/subscribe-with-Stripe">
                                        <div className="thumb-playicon g-playicon">
                                            <i className="fas fa-play g-fa-play" />
                                        </div>
                                    </Link>
                                    </div>
                                    </div>
                                                
                                                            
                                    
                                    <div className="container g-discription">
                                        <div className="row">
                                            <div className="thumb-desc "> 
                                                {/* {this.getYear(movie.title)}{" | "}{this.getFormatedDuration(movie.duration)} */}
                                            </div>
                                            <span className="small yellow-clr g-star">
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="far fa-star" />
                                                <i className="far fa-star" />
                                            </span>
                                        </div> 
                                </div>
                                
                            
                                
                                    
                            
                                        
                                {isActive && <Mark />}

                                </div>

                                
                            </div>
                        </MediaQuery>

                    </React.Fragment>
                      
                        
                    );
                }}
            </SliderContext.Consumer>
        );
    }
}

export default Item;