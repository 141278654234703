import React, { Component } from "react";

import { Container, Grid } from "@material-ui/core";

import api from "../../Environment";
import ContentLoader from "../Static/contentLoader";
import { Link } from "react-router-dom";

class ViewAll extends Component {
  state = {
    videoList: null,
    loading: true,
  };
  componentDidMount() {
    if (this.props.location.state) {
      //
    } else {
      window.location = "/home";
    }
    let inputData;
    let apiURL;
    if (this.props.location.state.videoType != undefined) {
      inputData = {
        skip: 0,
        cast_crew_id: this.props.location.state.cast_crew_id,
      };
      apiURL = "v4/cast_crews/videos";
    } else {
      inputData = {
        skip: 0,
        url_type: this.props.location.state.url_type,
        url_type_id: this.props.location.state.url_type_id,
        page_type: this.props.location.state.page_type,
        category_id: this.props.location.state.category_id,
        sub_category_id: this.props.location.state.sub_category_id,
      };
      apiURL = "see_all";
    }
    this.viewAllApiCall(inputData, apiURL);
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    let inputData;
    let apiURL;
    if (nextProps.location.state.videoType != undefined) {
      inputData = {
        skip: 0,
        cast_crew_id: nextProps.location.state.cast_crew_id,
      };
      apiURL = "v4/cast_crews/videos";
    } else {
      inputData = {
        skip: 0,
        url_type: nextProps.location.state.url_type,
        url_type_id: nextProps.location.state.url_type_id,
        page_type: nextProps.location.state.page_type,
        category_id: nextProps.location.state.category_id,
        sub_category_id: nextProps.location.state.sub_category_id,
      };
      apiURL = "see_all";
    }
    this.viewAllApiCall(inputData, apiURL);
  }

  viewAllApiCall = (inputData, apiURL) => {
    api
      .postMethod(apiURL, inputData)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            videoList: response.data.data,
          });
        } else {
        }
      })
      .catch(function(error) {});
  };

  chunkArray(myArray, chunk_size) {
    let results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }

  render() {
    // Usage

    let result = null;

    // Split in group of 3 items
    if (this.state.loading) {
      return <ContentLoader />;
    } else {
      result = this.chunkArray(this.state.videoList, 100);
    }

    // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]

    return (
      <Container>
        <h3 className="colorWhite">{this.props.location.state.title}</h3>
        <Grid className="gridContainer" spacing={12}>
          {result.map((res) =>
            res.map((movie) => (
              <Grid>
                <Link
                  to={{
                    pathname: `/detail/${movie.admin_video_id}`,
                    movie: movie,
                  }}
                >
                  <img
                    className="item  gridImage item5 sliderthumb-text swiper-lazy"
                    alt=""
                    src={movie.default_image}
                  />
                </Link>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
      // <Container>
      //   <h3 className="colorWhite">
      //     {this.props.location.state.title}
      //     <i className="fas fa-angle-right ml-2" />
      //   </h3>
      //   {result.map((res) => (
      //     <ImageList
      //       gap={12}
      //       sx={{
      //         mb: 8,
      //         gridTemplateColumns:
      //           "repeat(auto-fill, minmax(280px, 1fr))!important",
      //       }}
      //     >
      //       {res.map((movie) => (
      //         // <Card key={movie.admin_video_id}>
      //         <ImageListItem
      //           sx={{ height: "100% !important", width: "100px !important" }}
      //         >
      //           <Link
      //             to={{
      //               pathname: `/detail/${movie.admin_video_id}`,
      //               movie: movie,
      //             }}
      //           >
      //             <img className="" alt="" src={movie.default_image} />
      //           </Link>
      //         </ImageListItem>
      //         // {/* </Card> */}
      //       ))}
      //     </ImageList>
      //   ))}
      // </Container>

      // <div className="main p-40">
      //   <div className="main-slidersecn">
      //     <h3 className="colorWhite">
      //       {this.props.location.state.title}
      //       <i className="fas fa-angle-right ml-2" />
      //     </h3>
      //     {result.map((res) => (
      //       <Box sx={{ width: "100%" }}>
      //         <Grid
      //           container
      //           rowSpacing={1}
      //           columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      //         >
      //           {res.map((movie) => (
      //             <Grid item xs={2}>
      //               <Link
      //                 to={{
      //                   pathname: `/detail/${movie.admin_video_id}`,
      //                   movie: movie,
      //                 }}
      //               >
      //                 <img
      //                   className="item itemAll  item5 sliderthumb-text swiper-lazy"
      //                   alt=""
      //                   src={movie.default_image}
      //                 />
      //               </Link>
      //             </Grid>
      //           ))}
      //         </Grid>
      //       </Box>

      // <Swiper
      //   key={res.index}
      //   spaceBetween={20}
      //   slidesPerView={4}
      //   grid={{
      //     row: 4,
      //     fill: "row",
      //   }}
      //   modules={[Grid]}
      //   className="mySwiper"
      // >
      //   {res.map((movie) => (
      //     <SwiperSlide key={movie.admin_video_id}>
      //       <Link
      //         to={{
      //           pathname: `/detail/${movie.admin_video_id}`,
      //           movie: movie,
      //         }}
      //       >
      //         <img
      //           className="item  item5 sliderthumb-text swiper-lazy"
      //           alt=""
      //           src={movie.default_image}
      //         />
      //       </Link>
      //     </SwiperSlide>
      //   ))}
      // </Swiper>
      //     ))}
      //   </div>
      //   <div className="height-100" />
      // </div>
    );
  }
}

export default ViewAll;
