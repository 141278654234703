import React, { Component } from "react";
import { translate, getLanguage } from "react-multi-lang";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import ContentRow from "../George/ContentRow";

import api from "../../Environment";
import Slider from "../NewUI/MainSlider";

//check mobile Devices
import MediaQuery from 'react-responsive';

import emailjs from 'emailjs-com';

class AboutPage extends Component {
    state = {
        HomeSettings: [],
        loading: true,
         maindata: null,
        errorHandle: 0,
        loadingContents:true,
    };
    componentDidMount() {

        this.fetchConfig();
    }

    async fetchConfig() {
        const response = await fetch(apiConstants.homeSettingsUrl);

        const homeResonse = await response.json();

        this.setState({
            loading: false,
            HomeSettings: homeResonse.data,
        });

    }

    sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_elhyb9h', 'template_c22vm9f', e.target, 'user_tfSck6wWeUEhT5VDLgfKv').then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        const { loading } = this.state;

        return (
            <div>                
                {loading ? (
                    <div>
                        
                    </div>
                ) : (
                        
                        
                        <div>
                            <MediaQuery minDeviceWidth={1224}>
                            <div className="g-contact-page-header">
                    <div className="g-logo-div">
                        <Link to="/">
                                <img
                                    src={configuration.get("configData.site_logo")}
                                    className="site-logo "
                                    alt="site_logo"
                                />
                        </Link>
                                </div>
                                
                    <ul className="g-header-menu-list">                                     
                                    <div className="g-hover-img"><li><a href="/home">Home</a></li></div>
                                    {/* <div className="g-hover-img"><li><a href="#">Collection</a></li></div> */}
                    </ul> 
                    
                
                                                                                    
                    {/* <ul className="g-header-menu-list">
                      <div className="g-hover-img"><li><a href="/about-us">about</a></li></div>                                       
                        <div className="g-hover-img"><li><a href="#">Collection</a></li></div>
                        <div className="g-hover-img"><li><a href="#">BLOG</a></li></div>
                        <div className="g-hover-img"><li><a href="/contact-us">Contact</a></li></div>
                        <li><p>|</p></li>
                        <div className="g-hover-img"><li><a href="#">Login</a></li></div>
                        <div className="g-hover-img"><li><a href="#">sign up</a></li></div>
                        <li><Link to="/login" className="btn btn-danger g-btn-header">Try 7 days free</Link></li>			                                	                                                                                                
                    </ul>  */}
                                        
                                   
                    {/* <Link to="/login" className="btn btn-danger signin">{t("signin")}</Link> */}
                </div>
                        <div className="landing-banner-sec">
                            <MediaQuery minDeviceWidth={1224}>
                                <img
                                    className="about-banner-img"
                                    // src={HomeSettings.home_page_bg_image}
                                        srcSet="assets/img/contact/bg-image-contact-us.jpg 1000w,
                                    // assets/img/banner_medium.jpg 1500w,
                                    // assets/img/banner_large.jpg 1800w"
                                    alt={configuration.get("configData.site_name")}
                                />
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={1224}>
                                <img
                                    className="about-banner-img"
                                    // src={HomeSettings.home_page_bg_image}
                                        srcSet="assets/img/contact/bg-image-contact-us.png 1000w,
                                    // assets/img/header-bg-image.png 1500w,
                                    // assets/img/header-bg-image.png 1800w"
                                    alt={configuration.get("configData.site_name")}
                                />

                            </MediaQuery>
                                

                            <div className="banner-black-overlay">
                                <div className="img_container">
                                    <div className="text-center contact-container-text">
                                        <h2 className="benner-text-head langing-about-backimage">Contact customer service</h2>                                  
                                        <h6 className="g-about-h6">We love hearing from you!</h6>
                                        <div className="g-about-welcome">
                                           
                                                <img
                                                    src="assets/img/contact/speech-bubble.png"
                                                    className="contact-logo "
                                                    alt="site_logo"
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                        </div>

                               
                            



                            <div className=" main_back g-contact-background">
                                <div className="tab-module-wrapper">
                                    {/* <div className="text-center g-about-text-center">
                                        <h1>Anywhere, Anytime.</h1>
                                         <p className="g-about-p-text-center">Experience more Shudder by connecting your account to all your favorite streaming devices. Switch devices mid-stream without losing your place.</p>
                                    </div> */}
                                    <div className="row g-s-row">
                                        <div className="column g-contact-column-text">
                                            <h1 className="g-contact-h1">You may reach out to customer service anytime by emailing:</h1>
                                            <p className="g-contact-red-text">Support@HORRORMAX.COM</p>
                                            <p className="g-acontact-p-text-center">Customer inquires will be answered in the order they are received M-F 9am-6pm EST.</p>
                                            <p className="g-contact-gray-text">HORRORMAX is always looking for the next great spine tingling film or series! If you have a film, series, or content catalog you would like to make available through HORRORMAX, please email</p>
                                            <p className="g-contact-red-text">Distribution@HORRORMAX.com</p>
                                        </div>
                                        <div className="column g-column-img">
                                            <div className="g-contact-container">
                                                <form className="row g-contact-form" onSubmit={this.sendEmail}>
                                                    <input type="text" className="g-input form-control" name="name" placeholder="Your Name" required></input>
                                                    <input type="email" className="g-input form-control" name="email" placeholder="Your Email" required></input>
                                                    <textarea className="g-textarea form-control" rows="10"  name="message" placeholder="Your Message" required></textarea>
                                                    <input type="submit" className="form-control btn g-form-btn" value="SEND MESSAGE"></input>
                                                    <div class="form-group form-check">
                                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" required/>
                                                        <a href="https://horrormax.com/page/terms"  class="form-check-label g-form-lable">I ACCEPT TERMS & CONDITIONS</a>
                                                    </div>
                                                    {/* <input type="checkbox" className="form-check-input" name="checkbox"></input> <label className="form-check-label" for="checkbox">I ACCEPT TERMS & CONDITIONS</label> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        <div className="">
                            <MediaQuery minDeviceWidth={1224}>
                                    <img className="about-banner-img" srcSet="assets/img/contact/conact-us-image.jpg" alt={configuration.get("configData.site_name")}>
                                        
                                        
                                </img>
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={1224}>
                                     <img className="about-banner-img" srcSet="assets/img/contact/conact-us-image.jpg"  alt={configuration.get("configData.site_name")}/>
                            </MediaQuery>
                                

                            <div className="g-contact-apps">
                                <div className="img_container">
                                        <div className=" main_back">
                                            <div className="tab-module-wrapper">
                                                {/* <h2 className="">Stream everywhere</h2> */}
                                                </div>
                                <div className="tab-module-wrapper">
                                 
                                    {/* <div className="row g-s-row g-about-s-row">
                                        <div className="column g-column-text g-about-column">
                                            <div className="g-contact-icon-apps">
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-amazon.png" alt="Tv"/>
                                                </div>
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-apple.png" alt="Tv"/>
                                                </div>
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-google.png" alt="Tv"/>
                                                </div>                                           
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-xbox.png" alt="Tv"/>
                                                </div>
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-roku.png" alt="Tv"/>
                                                </div>
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/playstation.png" alt="Tv"/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div> */}
                                </div>
                            </div>
                                </div>
                            </div>
                                
                        </div>
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={1224}>
                                <div className="g-c-contact-page-header">
                                    <div className="g-logo-div">
                                        <Link to="/">
                                                <img
                                                    src={configuration.get("configData.site_logo")}
                                                    className="c-site-logo "
                                                    alt="site_logo"
                                                />
                                        </Link>
                                                </div>

                                    <ul className="g-cc-header-menu-list">                                     
                                                    <div className="c-g-hover-img"><li><a href="/home">Home</a></li></div>
                                                    {/* <div className="g-hover-img"><li><a href="#">Collection</a></li></div> */}
                                    </ul>
                                </div>

                                <div className="landing-banner-sec">
                                    <img className="about-banner-img" srcSet="assets/img/Responsive/Contact/contact-bg-image.jpg 800w" alt={configuration.get("configData.site_name")}/>                          
                                    <div className="banner-black-overlay">
                                        <div className="img_container">
                                            <div className="text-center c-contact-container-text">
                                                        <h2 className="benner-text-head c-langing-about-backimage">Contact</h2>
                                                <h2 className="benner-text-head c-langing-about-backimage">customer service</h2>        
                                                <h6 className="c-g-about-h6">We love hearing from you!</h6>
                                                <div className="g-about-welcome">
                                                
                                                        <img
                                                            src="assets/img/contact/speech-bubble.png"
                                                            className="c-contact-logo "
                                                            alt="site_logo"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>                               
                                </div>
                                
                                
                                <div className=" main_back g-contact-background">
                                    <div className="tab-module-wrapper">
                                        {/* <div className="text-center g-about-text-center">
                                            <h1>Anywhere, Anytime.</h1>
                                            <p className="g-about-p-text-center">Experience more Shudder by connecting your account to all your favorite streaming devices. Switch devices mid-stream without losing your place.</p>
                                        </div> */}

                                            <div className="column g-contact-column-text">
                                                <h1 className="g-contact-h1">You may reach out to customer service anytime by emailing:</h1>
                                                <p className="g-contact-red-text">Support@HORRORMAX.COM</p>
                                                <p className="g-acontact-p-text-center">Customer inquires will be answered in the order they are received M-F 9am-6pm EST.</p>
                                                <p className="g-contact-gray-text">HORRORMAX is always looking for the next great spine tingling film or series! If you have a film, series, or content catalog you would like to make available through HORRORMAX, please email</p>
                                                <p className="g-contact-red-text">Distribution@HORRORMAX.com</p>
                                            </div>                                   
                                    </div>
                                </div>

                                 <div className=" main_back g-contact-background">
                                    <div className="tab-module-wrapper">
                                       <div className="column g-column-img">
                                            <div>
                                                <form className="row g-contact-form" onSubmit={this.sendEmail}>
                                                    <input type="text" className="g-input form-control" name="name" placeholder="Your Name" required></input>
                                                    <input type="email" className="g-input form-control" name="email" placeholder="Your Email" required></input>
                                                    <textarea className="g-textarea form-control" rows="10"  name="message" placeholder="Your Message" required></textarea>
                                                    <input type="submit" className="form-control btn g-form-btn" value="SEND MESSAGE"></input>
                                                    <div class="form-group form-check">
                                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" required/>
                                                        <a href="https://horrormax.com/page/terms"  class="form-check-label c-g-form-lable">I ACCEPT TERMS & CONDITIONS</a>
                                                    </div>
                                                    {/* <input type="checkbox" className="form-check-input" name="checkbox"></input> <label className="form-check-label" for="checkbox">I ACCEPT TERMS & CONDITIONS</label> */}
                                                </form>
                                            </div>
                                        </div>                           
                                    </div>
                                </div>


                                 

                                
                            </MediaQuery>
                            </div>
                            
                            
                )}
            </div>
        );
    }
}

export default translate(AboutPage);
