import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import api from "../../Environment";

import HomePageBanner from "../NewUI/homePageBanner";
import ContentLoader from "../Static/contentLoader";

import configuration from "react-global-configuration";

import { translate } from "react-multi-lang";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Lazy, Navigation, FreeMode } from "swiper";

import NavBarCatalog from "../../components/Auth/NavCatalog";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import MediaQuery from "react-responsive";

const Catalog = () => {
  const [maindata, setMaindata] = useState();
  const [errorHandle, setErrorHandle] = useState(0);
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState();
  const [loadingHomeSecondSection, setLoadingHomeSecondSection] = useState(
    true
  );
  const [loadingSeriesSection, setLoadingSeriesSection] = useState(true);
  const [homeSecondData, setHomeSecondData] = useState();
  const [seriesData, setSeriesData] = useState();
  const [take, setTake] = useState(5);
  const [skip, setSkip] = useState(10);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef();
  const lastRowRef = useCallback((node) => {
    if (isLoadingMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          LoadMore();
        }
      },
      [isLoadingMore, hasMore]
    );

    if (node) observer.current.observe(node);
    // console.log("node", node);
  });

  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const inputData = {
      page_type: "HOME",
      id: 1,
      sub_profile_id: 10,
      token: "2y10rvPFstItwb4fnOTHJbJKaCpmoj3fRtXjgK93fKyKSzQz6dckvAvm",
    };
    api
      .postMethod("home_first_section", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let maindata = response.data.data;
          let banner = response.data.banner;

          // setMaindata(maindata);
          setBanner(banner);
          setLoading(false);
        } else {
          let errorHandle = 1;
        }
      })
      .catch(function(error) {});

    loadSecondData(inputData);
  };

  const loadSeriasData = async () => {
    const inputData = {
      page_type: "HOME",
      id: 1,
      sub_profile_id: 10,
      token: "2y10rvPFstItwb4fnOTHJbJKaCpmoj3fRtXjgK93fKyKSzQz6dckvAvm",
    };
    await api
      .postMethod("home_series_section", inputData)
      .then((response) => {
        if (response.data.success === true) {
          setSeriesData(response.data.data);
          setLoadingSeriesSection(false);
        } else {
          let errorHandle = 1;
          setErrorHandle(errorHandle);
        }
      })
      .catch(function(error) {});

    // End
  };
  // LandingPageWithRows
  const loadSecondData = async (inputData) => {
    const inputData2 = {
      page_type: "HOME",
      id: 1,
      sub_profile_id: 10,
      token: "2y10rvPFstItwb4fnOTHJbJKaCpmoj3fRtXjgK93fKyKSzQz6dckvAvm",
      take: 5,
      skip: 0,
    };
    await api
      .postMethod("home_second_section", inputData2)
      .then((response) => {
        if (response.data.success === true) {
          setHomeSecondData(response.data.data);
          setLoadingHomeSecondSection(false);
        } else {
          let errorHandle = 1;
          setErrorHandle(errorHandle);
        }
      })
      .catch(function(error) {});

    LoadNext();
  };

  const LoadNext = async () => {
    setIsLoadingMore(true);

    const inputData2 = {
      page_type: "HOME",
      id: 1,
      sub_profile_id: 10,
      token: "2y10rvPFstItwb4fnOTHJbJKaCpmoj3fRtXjgK93fKyKSzQz6dckvAvm",
      take: 5,
      skip: 5,
    };
    await api
      .postMethod("home_second_section_new", inputData2)
      .then((response) => {
        if (response.data.success === true) {
          setHomeSecondData((prevData) => {
            return [...prevData, ...response.data.data];
          });
          setIsLoadingMore(false);
          if (response.data.data.length === 0) {
            setHasMore(false);
            setIsLoadingMore(true);
            loadSeriasData();
          }
        } else {
          let errorHandle = 1;
        }
      })
      .catch(function(error) {});
    LoadMore();
  };

  const LoadMore = async () => {
    setIsLoadingMore(true);

    const inputData2 = {
      page_type: "HOME",
      id: 1,
      sub_profile_id: 10,
      token: "2y10rvPFstItwb4fnOTHJbJKaCpmoj3fRtXjgK93fKyKSzQz6dckvAvm",
      take: take,
      skip: skip,
    };
    await api
      .postMethod("home_second_section_new", inputData2)
      .then((response) => {
        if (response.data.success === true) {
          setSkip(skip + 5);

          setHomeSecondData((prevData) => {
            return [...prevData, ...response.data.data];
          });
          setIsLoadingMore(false);
          if (response.data.data.length === 0) {
            setHasMore(false);
            setIsLoadingMore(true);
            loadSeriasData();
          }

          // this.setState({
          //   skip: this.state.take,
          //   take: this.state.take + 5,
          //   homeSecondData: [
          //     ...this.state.homeSecondData,
          //     ...response.data.data,
          //   ],
          // });
          // this.setState({ hasMore: false });
        } else {
          let errorHandle = 1;
          // this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  };

  const renderVideoList = (maindata, index) => {
    return (
      <React.Fragment key={index}>
        <div className="main-slidersec-new">
          <Link
            to={{
              pathname: "/view-all",
              state: {
                url_type: maindata.url_type,
                url_type_id: maindata.url_type_id,
                page_type: "HOME",
                title: maindata.title,
              },
            }}
          >
            <h3 className="">
              {maindata.title}
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>
          <MediaQuery minDeviceWidth={1224}>
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              navigation={true}
              slidesPerGroup={2}
              speed={900}
              loop={maindata.data.length < 6 ? false : true}
              lazy={true}
              freeMode={true}
              allowTouchMove={false}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  slidesPerGroup: 4,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                  slidesPerGroup: 6,
                },
              }}
              modules={[Navigation, Lazy, FreeMode]}
              preloadImages={false}
              // onClick={(swiper) => alert(swiper.activeIndex)}
              onSlideChange={(swiper) => {
                if (swiper.navigation.prevEl) {
                  swiper.isBeginning
                    ? swiper.navigation.prevEl.setAttribute("hidden", true)
                    : swiper.navigation.prevEl.removeAttribute("hidden");
                }
              }}
              onSwiper={(swiper) => {
                swiper.slidePrev(0);
              }}
            >
              {maindata.data.map((movie) => (
                <SwiperSlide key={movie.admin_video_id}>
                  <div className="dandash"></div>
                  <Link
                    to="/subscribe-with-Stripe"
                    className="item_focus item5"
                  >
                    <img
                      className="item  item5 sliderthumb-text swiper-lazy"
                      alt=""
                      src={movie.default_image}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </MediaQuery>

          <MediaQuery maxDeviceWidth={1224}>
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              navigation={false}
              slidesPerGroup={2}
              speed={900}
              loop={maindata.data.length < 6 ? false : true}
              lazy={true}
              freeMode={true}
              allowTouchMove={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  slidesPerGroup: 4,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                  slidesPerGroup: 6,
                },
              }}
              modules={[Navigation, Lazy, FreeMode]}
              preloadImages={false}
              // onClick={(swiper) => alert(swiper.activeIndex)}
              onSlideChange={(swiper) => {
                if (swiper.navigation.prevEl) {
                  swiper.isBeginning
                    ? swiper.navigation.prevEl.setAttribute("hidden", true)
                    : swiper.navigation.prevEl.removeAttribute("hidden");
                }
              }}
              onSwiper={(swiper) => {
                swiper.slidePrev(0);
              }}
            >
              {maindata.data.map((movie) => (
                <SwiperSlide key={movie.admin_video_id}>
                  <div className="dandash"></div>
                  <Link
                    to="/subscribe-with-Stripe"
                    className="item_focus item5"
                  >
                    <img
                      className="item  item5 sliderthumb-text swiper-lazy"
                      alt=""
                      src={movie.default_image}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </MediaQuery>

          {/* } */}
        </div>
        {!loadingHomeSecondSection ? (
          homeSecondData.length === index + 1 ? (
            <div ref={lastRowRef}></div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="main-sec-content">
      {/* nav bar */}
      <NavBarCatalog></NavBarCatalog>

      {/* <div className="header-height"></div> */}

      {/* nav bar */}
      {loading ? <ContentLoader /> : <HomePageBanner banner={banner} />}
      <div className="main p-40">
        {/* {renderMyList} */}

        {/* {loading
          ? ""
          : maindata.map((mainDa, index) =>
              mainDa.data.length === 0
                ? ""
                : loading
                ? ""
                : renderVideoList(mainDa, index)
            )} */}

        {loadingHomeSecondSection
          ? ""
          : homeSecondData.map(
              (mainDa, index) => renderVideoList(mainDa, index)
              // <InfiniteScroll
              //   dataLength={homeSecondData.length}
              //   next={this.LoadMore()}
              //   hasMore={true}
              //   loader={<h4>Loading...</h4>}
              // >
              //   {homeSecondData.map((mainDa, index) =>
              //     this.renderVideoList(mainDa, index)
              //   )}
              // </InfiniteScroll>
            )}
        {loadingSeriesSection
          ? ""
          : seriesData.map((mainDa, index) =>
              mainDa.data.length === 0
                ? ""
                : loading
                ? ""
                : renderVideoList(mainDa, index)
            )}

        {isLoadingMore && hasMore ? (
          <ShimmerSimpleGallery card imageHeight={140} row={1} col={4} />
        ) : (
          ""
        )}

        <div className="height-100" />
      </div>
    </div>
  );
};

export default translate(Catalog);
