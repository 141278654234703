import React, { Component } from "react";
import api from "../../../Environment";
import { Link } from "react-router-dom";

import MediaQuery from 'react-responsive';

import {
  setTranslations,
  setDefaultLanguage,
  setLanguage,
  translate,
  getLanguage,
} from "react-multi-lang";
import en from "../../translation/en.json";
import pt from "../../translation/pt.json";

import configuration from "react-global-configuration";

const $ = window.$;

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: this.props.data,
      footer_pages1: [],
      footer_pages2: [],
      loading: true,
      footerList: null,
    };
  }

  componentDidMount() {
    api
      .getMethod("pages/list")
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            footerList: response.data.data,
          });
        } else {
        }
      })
      .catch(function(error) {});

    var footerHeight = $("#footer").outerHeight();
    var deviceheight = $(window).outerHeight();
    var contentheight = deviceheight - footerHeight - 66;
    $(".main-sec-content").css("min-height", contentheight);

    // $(".bottom-height").height(footerHeight);
    // Call api function

    

    if (configuration.get("configData.footer_pages1")) {
      this.setState({
        footer_pages1: configuration.get("configData.footer_pages1"),
      });
    }
    if (configuration.get("configData.footer_pages2")) {
      this.setState({
        footer_pages2: configuration.get("configData.footer_pages2"),
      });
    }
  }

  handleChangeLang = ({ currentTarget: input }) => {
    console.log(input.value);
    setLanguage(input.value);
    localStorage.setItem("lang", input.value);
    // window.location.reload();
  };

  Quantcast = () => {
   
            var elem = document.createElement('script');
            elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
            elem.async = true;
            elem.type = "text/javascript";
            var scpt = document.getElementsByTagName('script')[0];
            scpt.parentNode.insertBefore(elem, scpt);
            };

  QuantcastEvent = () => {
    var _qevents = _qevents || [];
    _qevents.push({
      qacct: "p-null",
      uid: "__INSERT_EMAIL_HERE__"
    })
  };

  render() {
    const { t } = this.props;
    const { loading, footerList } = this.state;
    return (
      <div className="main-footer-sec-content">
         <MediaQuery minDeviceWidth={1224}>
        <div className="bottom-height"></div>
        <div className="footer" id="footer">
          <div className="site-footer">
            {/* <p className="footer-top">
              <Link to="#">{t("questions_contact_us")}</Link>
            </p> */}

              <div className=" main_back">
                                    <div className="g-tab-module-wrapper">
                                        <nav class="navigation">
                                            <div class="logo">
				                                <Link to="/">
                                                    <img src={configuration.get("configData.site_logo")} className="site-logo" alt="site_logo"/>
                                                </Link>
                                            </div>
                                            
                                            {/* <ul class="g-footer-menu-list">
			                                	      <div className="g-hover-img"><li><a className="g-a" href="/about-us">about</a></li></div>
			                                	      <div className="g-hover-img"><li><a  className="g-a" href="#">Collection</a></li></div>
			                                	      <div className="g-hover-img"><li><a  className="g-a" href="#">BLOG</a></li></div>
			                                	      <div className="g-hover-img"><li><a  className="g-a" href="/contact-us">Contact</a></li></div>
                                              <div className="g-hover-img-partner"><li><a className="g-a" href="#">Partner Program</a></li></div>
                                              <div className="g-hover-img"><li><a  className="g-a" href="#">Support</a></li></div>
                                            </ul>
                                         */}
                                             {/* <ul class="menu-list">
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a className="g-a" href="#">about us</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a  className="g-a" href="#">Movies Collection</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a  className="g-a" href="#">BLOG</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a  className="g-a" href="#portfolio">Contact Us</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a className="g-a" href="#">Partner Program</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a  className="g-a" href="#">Support</a></li>
                                             </div>
			                                	
			                                	
			                                	
                                                
                                                
                                            </ul> */}
                                            {/* <div class="humbarger">
			                                	<div class="bar"></div>
			                                	<div class="bar2 bar"></div>
			                                	<div class="bar"></div>
			                                </div> */}

                                        </nav>
                                    </div>
            </div>
          </div>


          <div className="site-footer g-site-footer">   
            <div class="g-f-container">
                <div className=" main_back">
                                    <div className="g-tab-module-wrapper">
                                        <nav class="navigation">
                  <div class="logo ">
                       <ul class="g-m-footer">
			                                	<li className="g-Policy"><a className="g-a" href="/page/terms">Terms of Use</a></li>
                          <li><a className="g-a" href="/page/privacy">Privacy Policy</a></li>
                          <li><a className="g-a g-a-contact" href="/contact-us">Contact</a></li>
                                        {/* <li><a className="g-a" href="#">Cookies Policy</a></li> */}
                      {/* <li><p className="g-a">|</p></li> */}
                      {/* <li><p  className="g-a g-f-gray">Do not sell my information</p></li> */}
			                                	
                                        </ul>
				                               
                                            </div>
                                            
                                            <ul class="menu-list g-icon">
			                                	    <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-facebook.svg" alt=""/></a></li>
			                                	    {/* <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-vimeo-v.svg" alt=""/></a></li> */}
			                                	    <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-youtube.svg" alt=""/></a></li>
			                                	    {/* <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/simple-tiktok.svg" alt=""/></a></li> */}
                                            <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-snapchat.svg" alt=""/></a></li>
                                            <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-pinterest.svg" alt="" /></a></li>
                                            <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-instagram.svg" alt="" /></a></li>
                                            <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-twitter.svg" alt=""/></a></li>
                                        </ul>
                                        
                                        </nav>
                                    </div>
            </div>

            </div>
            
            




          
            <p className="footer-bottom">
              {/* {configuration.get("configData.site_name")} */}
            </p>
          </div>
        </div>

        {/* GDPR TAG */}
        {/* <!-- Quantcast Tag --> */}
        <script type="text/javascript">
          
          {this.Quantcast()};
          {this.QuantcastEvent()};

           
        </script>

        <noscript>
          <div style="display:none;">
            <img src="//pixel.quantserve.com/pixel/p-null.gif" border="0" height="1" width="1" alt="Quantcast"/>
          </div>
        </noscript>
        {/* <!-- End Quantcast tag --> */}

</MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          <div className="bottom-height"></div>
        <div className="footer" id="footer">
          <div className="site-footer">
            {/* <p className="footer-top">
              <Link to="#">{t("questions_contact_us")}</Link>
            </p> */}

              <div className=" main_back">
                                    <div className="g-tab-module-wrapper">
                                        <nav class="navigation">
                                            <div class="logo">
				                                <Link to="/">
                                                    <img src={configuration.get("configData.site_logo")} className="site-logo" alt="site_logo"/>
                                                </Link>
                                            </div>
                                            
                                           

                                        </nav>
                                    </div>
            </div>
          </div>


          <div className="site-footer g-site-footer">   
            <div class="g-f-container">
                <div className=" main_back">
                                    <div className="g-tab-module-wrapper">
                                        <nav class="navigation">
                  <div class="logo ">
                       <ul class="c-g-m-footer">
			                                	<li className="g-Policy"><a className="g-a" href="/page/terms">Terms of Use</a></li>
			                                	<li><a  className="g-a" href="/page/privacy">Privacy Policy</a></li>
                                        <li><a className="g-a c-g-a-contact" href="/contact-us">Contact</a></li>
			                                	
                                        </ul>
				                               
                                            </div>
                                            
                                           
                                        
                                        </nav>
                                    </div>
            </div>

              </div>
              
              <div class="g-f-container"><nav class="navigation c-nav">
                <div className=" main_back">
                   <ul class="menu-list g-icon c-icons">
			                                	    <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-facebook.svg" alt=""/></a></li>
			                                	    {/* <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-vimeo-v.svg" alt=""/></a></li> */}
			                                	    <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-youtube.svg" alt=""/></a></li>
			                                	    {/* <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/simple-tiktok.svg" alt=""/></a></li> */}
                                            <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-snapchat.svg" alt=""/></a></li>
                                            <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-pinterest.svg" alt="" /></a></li>
                                            <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-instagram.svg" alt="" /></a></li>
                                            <li><a className="g-a" href="#"><img className="g-social-icon" srcSet="assets/img/awesome-twitter.svg" alt=""/></a></li>
                                        </ul>

                </div></nav>
              </div>
            
            




          
            <p className="footer-bottom">
            </p>
          </div>
        </div>
         </MediaQuery>
        
      </div>
    );
  }
}

export default translate(Footer);
