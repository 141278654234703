import React, { useState } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
const NavBarCatalog = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  window.onscroll = () => {
    setIsScrolled(window.scrollY === 0 ? false : true);
    return () => (window.onscroll = null);
  };

  return (
    <div className={isScrolled ? "navbar_catalog scrolled" : "navbar_catalog"}>
      <div className="container">
        <Link className="" to="/home">
          <img
            src={configuration.get("configData.site_logo")}
            className=""
            alt="logo_img"
          />
        </Link>

        <div className="main-nav-btns">
          <Link to="/login" className="btn btn-danger login_btn_main_nav">
            <i />
            Login
          </Link>
          <Link
            to="/subscribe-with-Stripe"
            className="btn btn-danger login_btn_main_nav"
          >
            {<div>Subscribe</div>}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBarCatalog;
