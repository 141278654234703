import React, { Component } from "react";
import { translate, getLanguage } from "react-multi-lang";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import ContentRow from "../George/ContentRow";

import api from "../../Environment";
import Slider from "../NewUI/MainSlider";

//check mobile Devices
import MediaQuery from "react-responsive";

class LandingPage extends Component {
  state = {
    HomeSettings: [],
    loading: true,
    maindata: null,
    errorHandle: 0,
    loadingContents: true,
  };
  componentDidMount() {
    // Call api function
    console.log(getLanguage());

    this.fetchConfig();
    this.fechContents();
  }

  async fetchConfig() {
    const response = await fetch(apiConstants.homeSettingsUrl);

    const homeResonse = await response.json();

    this.setState({
      loading: false,
      HomeSettings: homeResonse.data,
    });
  }

  async fechContents() {
    const inputData = {
      page_type: "HOME",
      id: 1,
      sub_profile_id: 10,
      token: "2y10rvPFstItwb4fnOTHJbJKaCpmoj3fRtXjgK93fKyKSzQz6dckvAvm",
    };
    api
      .postMethod("home_landing_Page", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let maindata = response.data.data;
          this.setState({
            loading: false,
            maindata: maindata,
            loadingContents: false,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function(error) {});
  }

  renderVideoList = (maindata, index) => {
    return (
      <React.Fragment key={index}>
        <MediaQuery minDeviceWidth={1224}>
          <div className="main-slidersec g-main-slider">
            <div className="row">
              <h3 className="">Trending Now</h3>
              <Link
                to="/catalog"
                className="btn btn-danger banner-large-btn join-free g-btn"
              >
                See More
                <i className="fas fa-chevron-right ml-3" />
              </Link>
            </div>

            {/* { maindata.title === "Friends" ? "" : */}
            <Slider>
              {maindata.data.map((movie) => (
                <Slider.Item
                  movie={movie}
                  key={movie.admin_video_id}
                  count={maindata.data}
                >
                  item1
                </Slider.Item>
              ))}
            </Slider>

            {/* } */}
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          <div className="main-slidersec c-g-main-slider">
            <div className="row">
              <h3 className="">Trending Now</h3>
              <Link
                to="/catalog"
                className="btn btn-danger banner-large-btn join-free g-btn"
              >
                See More
                <i className="fas fa-chevron-right ml-3" />
              </Link>
            </div>

            {/* { maindata.title === "Friends" ? "" : */}
            <Slider>
              {maindata.data.map((movie) => (
                <Slider.Item
                  movie={movie}
                  key={movie.admin_video_id}
                  count={maindata.data}
                >
                  item1
                </Slider.Item>
              ))}
            </Slider>

            {/* } */}
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    const { loading, HomeSettings, maindata, loadingContents } = this.state;

    return (
      <div>
        {loading ? (
          <div></div>
        ) : (
          <div>
            <div className="g-landing-page-header">
              <div className="g-logo-div">
                <Link to="/">
                  <img
                    src={configuration.get("configData.site_logo")}
                    className="site-logo "
                    alt="site_logo"
                  />
                </Link>
              </div>

              <MediaQuery minDeviceWidth={1224}>
                <ul className="g-header-menu-list">
                  {/* <div className="g-hover-img"><li><a href="/about-us">about</a></li></div>                                        */}
                  {/* <div className="g-hover-img"><li><a href="#">Collection</a></li></div> */}
                  {/* <div className="g-hover-img"><li><a href="#">BLOG</a></li></div> */}
                  {/* <div className="g-hover-img"><li><a href="/contact-us">Contact</a></li></div> */}
                  {/* <li><p>|</p></li>  */}
                  <div className="g-hover-img">
                    <li>
                      <a href="/login">Login</a>
                    </li>
                  </div>
                  <div className="g-hover-img">
                    <li>
                      <a href="/subscribe-with-Stripe">sign up</a>
                    </li>
                  </div>
                  {/* <li><Link to="/login" className="btn btn-danger g-btn-header">Try 7 days free</Link></li>			                                	                                                                                                 */}
                </ul>
              </MediaQuery>

              <MediaQuery maxDeviceWidth={1224}>
                <ul className="g-header-menu-list">
                  {/* <div className="g-hover-img"><li><a href="/about-us">about</a></li></div>                                        */}
                  {/* <div className="g-hover-img"><li><a href="#">Collection</a></li></div> */}
                  {/* <div className="g-hover-img"><li><a href="#">BLOG</a></li></div> */}
                  {/* <div className="g-hover-img"><li><a href="/contact-us">Contact</a></li></div> */}
                  {/* <li><p>|</p></li>  */}
                  <div className="c-g-hover-img">
                    <li>
                      <a href="/login">Login</a>
                    </li>
                  </div>
                  <div className="c-g-hover-img">
                    <li>
                      <a href="/subscribe-with-Stripe">sign up</a>
                    </li>
                  </div>
                  {/* <li><Link to="/login" className="btn btn-danger g-btn-header">Try 7 days free</Link></li>			                                	                                                                                                 */}
                </ul>
              </MediaQuery>

              {/* <Link to="/login" className="btn btn-danger signin">{t("signin")}</Link> */}
            </div>
            <div className="landing-banner-sec ">
              <MediaQuery minDeviceWidth={1224}>
                <img
                  className="landing-banner-img"
                  // src={HomeSettings.home_page_bg_image}
                  srcSet="assets/img/head-bg-image-c.jpg 1000w,
                                    // assets/img/banner_medium.jpg 1500w,
                                    // assets/img/banner_large.jpg 1800w"
                  alt={configuration.get("configData.site_name")}
                />
                <div className="banner-black-overlay">
                  <div className="img_container">
                    <img
                      className="blood-img"
                      srcSet="assets/img/blood.png"
                      alt=""
                    ></img>
                    <div className="text-center header-container-text">
                      <h1 className="benner-text-head langing-header-backimage g-font">
                        WATCH IF YOU DARE!{" "}
                      </h1>
                      <h4 className="banner-text-para">
                        HORROR, SLASHERS, GORE & MORE!
                      </h4>
                      <h6>Monthly & Annual plans available. Cancel Anytime.</h6>
                      <div className="row siginBtn-center">
                        <div className="col-md-12">
                          <Link
                            to="/catalog"
                            className="btn btn-danger banner-large-btn join-free"
                          >
                            BROWSE OUR CATALOG
                            {/* <i className="fas fa-chevron-right ml-3" /> */}
                          </Link>
                        </div>
                        {/* <div className="col-md-12">
                                            <Link
                                                to="/login"
                                                className="btn btn-danger banner-large-btn join-free"
                                            >
                                                {t("signin")}
                                            </Link>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={1224}>
                <img
                  className="c-landing-banner-img"
                  // src={HomeSettings.home_page_bg_image}
                  srcSet="assets/img/header-bg-image.png 1000w,
                                    // assets/img/header-bg-image.png 1500w,
                                    // assets/img/header-bg-image.png 1800w"
                  alt={configuration.get("configData.site_name")}
                />
                <div className="c-banner-black-overlay">
                  <div className="img_container">
                    <img
                      className="c-blood-img"
                      srcSet="assets/img/blood.png"
                      alt=""
                    ></img>
                    <div className="text-center header-container-text">
                      <h1 className="benner-text-head langing-header-backimage c-g-font">
                        WATCH IF YOU DARE!{" "}
                      </h1>
                      <h4 className="banner-text-para">
                        HORROR, SLASHERS, GORE & MORE!
                      </h4>
                      <h6>Monthly & Annual plans available. Cancel Anytime.</h6>
                      <div className="row siginBtn-center">
                        <div className="col-md-12">
                          <Link
                            to="/catalog"
                            className="btn btn-danger c-banner-large-btn join-free"
                          >
                            BROWSE OUR CATALOG
                            {/* <i className="fas fa-chevron-right ml-3" /> */}
                          </Link>
                        </div>
                        {/* <div className="col-md-12">
                                            <Link
                                                to="/login"
                                                className="btn btn-danger banner-large-btn join-free"
                                            >
                                                {t("signin")}
                                            </Link>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </MediaQuery>

              {/* <img className='clouds-img' srcSet="assets/img/clouds-mage.png" alt=""></img> */}
            </div>

            <div className="main main_back g-main-background">
              <div className="tab-module-wrapper ">
                <div className="text-center">
                  <h1>YOUR SOURCE FOR ALL HORROR GENRES</h1>
                </div>

                <section>
                  <div className="services-grid">
                    <div className="service service1">
                      <div className="devices-img">
                        <img
                          src="assets/img/horrormax-banner-ice-cream-man.jpg"
                          alt="Tv"
                        />
                      </div>
                      {/* <i className="ti-bar-chart"></i> */}
                      {/* <h4>Wealth Management</h4> */}
                      <h3 className="g-c-h3">HUGE SELECTION</h3>

                      <p className="g-p g-p-c">
                        With over a thousand films from cult classics to new
                        independent films and new content added weekly, all in
                        one heart stopping place - HORRORMAX!
                      </p>

                      {/* <a href="#" className="cta g-a">Read More <span className="ti-angle-right"></span></a> */}
                    </div>

                    <div className="service service2">
                      {/* <i className="ti-light-bulb"></i> */}
                      <div className="devices-img">
                        <img
                          src="assets/img/horrormax-banner-the-night-he-came-home.jpg"
                          alt="Tv"
                        />
                      </div>
                      <h3 className="g-c-h3">A MUST </h3>
                      {/* <h4>Financial Planning</h4> */}
                      <p className="g-p g-p-c">
                        Horror fans will find everything they are looking in
                        HORRORMAX. We cover all horror genres that will keep you
                        up at night.
                      </p>
                      <p></p>

                      {/* <a href="#" className="cta g-a">Read More <span className="ti-angle-right"></span></a> */}
                    </div>

                    <div className="service service3">
                      <div className="devices-img">
                        <img
                          src="assets/img/horrormax-banner-american-scream-king.jpg"
                          alt="Tv"
                        />
                      </div>
                      {/* <i className="ti-money"></i> */}
                      {/* <h4>Investment Banking</h4> */}
                      <h3 className="g-c-h3">MEMBERSHIP</h3>

                      <p className="g-p g-p-c">
                        Monthly & Annual plans available. Watch HORRORMAX
                        Commercial Free. Cancel at anytime.
                      </p>
                      <p>
                        <p></p>
                      </p>
                      {/* <a href="#" className="cta g-a">Read more <span className="ti-angle-right"></span></a> */}
                    </div>
                  </div>
                </section>

                <MediaQuery minDeviceWidth={1224}>
                  <div className="row g-s-row">
                    {/* <div className="column g-column-text">
                                            <h3>HUGE SELECTION</h3>
                                            <p className="g-p">With over a thousand films from cult classics to new independent films and new content added weekly, all in one heart stopping place - HORRORMAX!</p>
                                            <h3>A MUST FOR HORROR FANS</h3>
                                            <p className="g-p">Horror fans will find everything they are looking in HORRORMAX. We cover all horror genres that will keep you up at night.</p>
                                             <h3>MEMBERSHIP</h3>
                                            <p className="g-p">Monthly & Annual plans available. Watch HOORORMAX Commercial Free. Cancel at anytime.</p>
                                        </div> */}
                    <div className="column g-column-img">
                      <div className="devices-img g-dev-img">
                        <img src="assets/img/devices-2.png" alt="Tv" />
                      </div>
                    </div>
                  </div>
                </MediaQuery>
              </div>
            </div>

            <div className="main main_back">
              <div className="tab-module-wrapper">
                <div className="text-center">
                  <h1>YOUR PREMIERE HORROR STREAMING SERVICE</h1>

                  <p className="g-text-center">
                    HORRORMAX is a subscription service built for the true lover
                    of all things horror. With over a thousand films
                  </p>
                  <p className="g-text-center-s">
                    in all niches of the horror genre and new content added
                    weekly, look no further than HORRORMAX.
                  </p>
                </div>
              </div>

              {/* <ContentRow></ContentRow> */}

              <div className="main main-g p-40">
                {loadingContents
                  ? ""
                  : maindata.map((mainDa, index) =>
                      mainDa.data.length === 0
                        ? ""
                        : loading
                        ? t("")
                        : this.renderVideoList(mainDa, index)
                    )}

                {/* <div className="height-100" /> */}
              </div>
            </div>

            {/* <div className="main main_back g-main-background">
                                <div className="tab-module-wrapper">
                                    <div className="text-center">
                                        <h1>YOUR SOURCE FOR ALL HORROR GENRES</h1>
                                    </div>
                                    <div className="row g-s-row">
                                        <div className="column g-column-text">
                                            <h3>HUGE SELECTION</h3>
                                            <p className="g-p">With over a thousand films from cult classics to new independent films and new content added weekly, all in one heart stopping place - HORRORMAX!</p>
                                            <h3>A MUST FOR HORROR FANS</h3>
                                            <p className="g-p">Horror fans will find everything they are looking in HORRORMAX. We cover all horror genres that will keep you up at night.</p>
                                             <h3>MEMBERSHIP</h3>
                                            <p className="g-p">Monthly & Annual plans available. Watch HOORORMAX Commercial Free. Cancel at anytime.</p>
                                        </div>
                                        <div className="column g-column-img">
                                            <div className="devices-img">
                                                        <img
                                                            src="assets/img/devices-2.png"
                                                            alt="Tv"
                                                        />
                                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

            <div className="landing-banner-sec">
              <MediaQuery minDeviceWidth={1224}>
                <img
                  className="g-tree-img"
                  srcSet="assets/img/trees-bg-img.jpg"
                  alt={configuration.get("configData.site_name")}
                />
              </MediaQuery>
              <MediaQuery maxDeviceWidth={1224}>
                <img
                  className="g-tree-img"
                  srcSet="assets/img/trees-bg-img.jpg"
                  alt={configuration.get("configData.site_name")}
                />
              </MediaQuery>
              <div>
                <div className="img_container">
                  <div className="text-center g-header-container-text">
                    <h1>DON’T BE LEFT IN THE DARK </h1>
                    <p className="g-p">
                      Check out what horror fans are dying to watch!
                    </p>
                    <div className="row siginBtn-center">
                      <MediaQuery minDeviceWidth={1224}>
                        <div className="col-md-12">
                          <Link
                            to="/subscribe-with-Stripe"
                            className="btn btn-danger banner-large-btn join-free"
                          >
                            Join Now
                          </Link>
                        </div>
                      </MediaQuery>
                      <MediaQuery maxDeviceWidth={1224}>
                        <div className="col-md-12">
                          <Link
                            to="/subscribe-with-Stripe"
                            className="btn btn-danger c-banner-large-btn join-free"
                          >
                            Join Now
                          </Link>
                        </div>
                      </MediaQuery>
                    </div>
                    <div className="main main_back">
                      <div className="tab-module-wrapper tab-module-wrapper-g">
                        <div className="row g-devices-row">
                          <div className="col-sm">
                            <img srcSet="assets/img/android.svg" alt="" />
                          </div>
                          {/* <div className="col-sm"><img srcSet="assets/img/iphone.svg" alt="" /></div> */}
                          {/* <div className="col-sm"><img srcSet="assets/img/apple-tv.svg" alt="" /></div> */}
                          <div className="col-sm">
                            <img srcSet="assets/img/roku.svg" alt="" />
                          </div>
                          <div className="col-sm">
                            <img
                              srcSet="assets/img/amazon-fire-tv.svg"
                              alt=""
                            />
                          </div>
                          {/* <div className="col-sm"><img srcSet="assets/img/xbox.svg" alt="" /></div> */}
                          <div className="col-sm">
                            <img srcSet="assets/img/chromecast.svg" alt="" />
                          </div>
                          {/* <div className="col-sm g-samsung" ><img srcSet="assets/img/samsung-smart-tv.svg" alt="" /></div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* read part */}
            <div className="landing-banner-sec landing-banner-sec-g">
              <MediaQuery minDeviceWidth={1224}>
                <img
                  className="g-background-red-img"
                  srcSet="assets/img/blood-bg-image.png"
                  alt={configuration.get("configData.site_name")}
                />
              </MediaQuery>
              <MediaQuery maxDeviceWidth={1224}>
                <img
                  className="g-background-red-img"
                  srcSet="assets/img/blood-image.png"
                  alt={configuration.get("configData.site_name")}
                />
              </MediaQuery>
              <div>
                <div className="img_container">
                  <div className="text-center g-header-container-text-red">
                    <img
                      className="g-red-img"
                      srcSet="assets/img/devices.png"
                      alt={configuration.get("configData.site_name")}
                    />
                    <h1 className="g-h1">Watch anywhere</h1>
                    {/* <div className="row g-white-row">
                                                <p className="g-p-white">Curabitur vulputate arcu odio, ac facilisis diam accumsan ut. Ut imperdiet et leo in vulputate. Sed eleifend lacus eu sapien sagittis imperdiet.</p>
                                            </div> */}

                    {/* <div className="row siginBtn-center">
                                                <div className="col-md-12" >
                                                    <Link
                                                        to="/subscribe-with-Stripe"
                                                        className="btn btn-danger banner-large-btn join-free g-join-now"
                                                    >
                                                        Try 7 days free
                                                        
                                                    </Link>
                                                </div>

                                            </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/*                          
                                <div className=" main_back">
                                    <div className="g-tab-module-wrapper">
                                        <nav class="navigation">
                                            <div class="logo">
				                                <Link to="/">
                                                    <img src={configuration.get("configData.site_logo")} className="site-logo" alt="site_logo"/>
                                                </Link>
                                            </div>
                                            
                                            <ul class="menu-list">
			                                	<li><a className="g-a" href="#">about us</a></li>
			                                	<li><a  className="g-a" href="#">Movies Collection</a></li>
			                                	<li><a  className="g-a" href="#">BLOG</a></li>
			                                	<li><a  className="g-a" href="#portfolio">Contact Us</a></li>
                                                <li><a className="g-a" href="#">Partner Program</a></li>
                                                <li><a  className="g-a" href="#">Support</a></li>
                                        </ul> */}

            {/* <ul class="menu-list">
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a className="g-a" href="#">about us</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a  className="g-a" href="#">Movies Collection</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a  className="g-a" href="#">BLOG</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a  className="g-a" href="#portfolio">Contact Us</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a className="g-a" href="#">Partner Program</a></li>
                                            </div>
                                            <div className="container g-hover-img">
                                                <li className="g-ul"><a  className="g-a" href="#">Support</a></li>
                                             </div>
			                                	
			                                	
			                                	
                                                
                                                
                                            </ul> */}
            {/* <div class="humbarger">
			                                	<div class="bar"></div>
			                                	<div class="bar2 bar"></div>
			                                	<div class="bar"></div>
			                                </div> */}
            {/* 
                                        </nav>
                                    </div>
                                </div> */}
          </div>
        )}
      </div>
    );
  }
}

export default translate(LandingPage);
