import React from "react";
import "../../NewUI/MainSlider/Content.scss";
import Helper from "../../Helper/helper";
import VideoOverView from "../../User/Video/videoOverView";
import ContentLoader from "../../Static/contentLoader";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Lazy, Navigation, FreeMode } from "swiper";
import MediaQuery from "react-responsive";

import { ShimmerContentBlock } from "react-shimmer-effects";
import VideoEpisode from "../../User/Video/videoEpisode";

const $ = window.$;
// const DATE_OPTIONS = {
//     year: "numeric",
//     month: "short"
// };

class VideoDetailsPage extends Helper {
  state = {
    videoDetailsFirst: null,
    loadingFirst: true,
    videoDetailsSecond: null,
    loadingSecond: true,
    suggestion: null,
    loadingSuggestion: true,
    nav: "overview",
    inputData: {
      admin_video_id: this.getMovieId(),
      skip: 0,
    },
    backImageUrl: null,
    index: 0,
  };

  getMovieId() {
    let str = window.location.href;
    str = str.split("/");
    const vidID = str[str.length - 1];

    return vidID;
  }

  componentDidMount() {
    this.scrollToTop();
    this.singleVideoFirst(this.state.inputData);
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentWillUnmount() {
    // clearInterval(this.interval);
  }

  getFormatedDuration(duration) {
    const hours = duration.split(":")[0];
    const minutes = duration.split(":")[1];
    const du = hours + " hr " + minutes + " m ";
    return du;
  }

  getTitle(title) {
    return title.replace(/ *\([^)]*\) */g, "");
  }

  getYear(year) {
    let ye = "";
    if (year.includes("(")) {
      const regExp = /\([^)]+\)/;
      ye = regExp.exec(year).toString();
      ye = ye.replace("(", "");
      ye = ye.replace(")", " ");
    }

    return ye;
  }

  componentWillReceiveProps(props) {
    this.setState({
      nav: "overview",
      loadingFirst: true,
      loadingSecond: true,
    });

    // this.forceUpdate();
    let inputData = {
      admin_video_id: this.getMovieId(),
    };

    this.singleVideoFirst(inputData);
  }

  renderEpisodesList(maindata) {
    console.log("maindata", maindata);
    return (
      <React.Fragment key={maindata.admin_video_id}>
        <div className="main-slidersec-new">
          <Link
            to={{
              pathname: "/view-all",
              state: {
                url_type: maindata.url_type,
                url_type_id: maindata.url_type_id,
                page_type: "HOME",
                title: maindata.title,
              },
            }}
          >
            <h3 className="">
              Episodes
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>
          <MediaQuery minDeviceWidth={1224}>
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              navigation={true}
              slidesPerGroup={2}
              speed={900}
              loop={false}
              lazy={true}
              freeMode={true}
              allowTouchMove={false}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  slidesPerGroup: 4,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                  slidesPerGroup: 6,
                },
              }}
              modules={[Navigation, Lazy, FreeMode]}
              preloadImages={false}
              // onClick={(swiper) => alert(swiper.activeIndex)}
              onSlideChange={(swiper) => {
                if (swiper.navigation.prevEl) {
                  swiper.isBeginning
                    ? swiper.navigation.prevEl.setAttribute("hidden", true)
                    : swiper.navigation.prevEl.removeAttribute("hidden");
                }
              }}
              onSwiper={(swiper) => {
                swiper.slidePrev(0);
              }}
            >
              {maindata.map((movie) => (
                <SwiperSlide key={movie.admin_video_id}>
                  <Link
                    to={{
                      pathname: `/detail/${movie.admin_video_id}`,
                      movie: movie,
                    }}
                    onClick={this.scrollToTop}
                  >
                    <img
                      className="item  item5 sliderthumb-text swiper-lazy"
                      alt=""
                      src={movie.default_image}
                    />
                  </Link>
                  <div className="episodInfo">
                    <span className="">{this.getTitle(movie.title)}</span>

                    {/* <span>{this.getFormatedDuration(movie.duration)}</span> */}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </MediaQuery>

          <MediaQuery maxDeviceWidth={1224}>
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              navigation={false}
              slidesPerGroup={2}
              speed={900}
              loop={maindata.length < 6 ? false : true}
              lazy={true}
              freeMode={true}
              allowTouchMove={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  slidesPerGroup: 4,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                  slidesPerGroup: 6,
                },
              }}
              modules={[Navigation, Lazy, FreeMode]}
              preloadImages={false}
              // onClick={(swiper) => alert(swiper.activeIndex)}
              onSlideChange={(swiper) => {
                if (swiper.navigation.prevEl) {
                  swiper.isBeginning
                    ? swiper.navigation.prevEl.setAttribute("hidden", true)
                    : swiper.navigation.prevEl.removeAttribute("hidden");
                }
              }}
              onSwiper={(swiper) => {
                swiper.slidePrev(0);
              }}
            >
              {maindata.map((movie) => (
                <SwiperSlide key={movie.admin_video_id}>
                  <div className="dandash"></div>
                  <Link
                    to={{
                      pathname: `/detail/${movie.admin_video_id}`,
                      movie: movie,
                    }}
                    onClick={this.scrollToTop}
                  >
                    <img
                      className="item  item5 sliderthumb-text swiper-lazy"
                      alt=""
                      src={movie.default_image}
                    />
                  </Link>
                  <div className="">
                    <h1 className="banner_video_title">
                      {this.getTitle(movie.title)}
                    </h1>
                    <h4 className="banner_video_details">
                      <span>{this.getYear(movie.title)}</span>
                      {this.getYear(movie.title) !== "" ? (
                        <span className="duration_sep"> | </span>
                      ) : null}
                      <span>{movie.video_resize_path}</span>
                      {movie.video_resize_path ? (
                        <span className="duration_sep"> | </span>
                      ) : null}

                      <span>{this.getFormatedDuration(movie.duration)}</span>
                    </h4>{" "}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </MediaQuery>

          {/* } */}
        </div>
      </React.Fragment>
    );
  }

  renderSuggestionList(maindata) {
    console.log("maindata", maindata);
    return (
      <React.Fragment key={maindata.admin_video_id}>
        <div className="main-slidersec-new">
          <Link
            to={{
              pathname: "/view-all",
              state: {
                url_type: maindata.url_type,
                url_type_id: maindata.url_type_id,
                page_type: "HOME",
                title: maindata.title,
              },
            }}
          >
            <h3 className="">
              Suggestions
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>
          <MediaQuery minDeviceWidth={1224}>
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              navigation={true}
              slidesPerGroup={2}
              speed={900}
              loop={maindata.length < 6 ? false : true}
              lazy={true}
              freeMode={true}
              allowTouchMove={false}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  slidesPerGroup: 4,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                  slidesPerGroup: 6,
                },
              }}
              modules={[Navigation, Lazy, FreeMode]}
              preloadImages={false}
              // onClick={(swiper) => alert(swiper.activeIndex)}
              onSlideChange={(swiper) => {
                if (swiper.navigation.prevEl) {
                  swiper.isBeginning
                    ? swiper.navigation.prevEl.setAttribute("hidden", true)
                    : swiper.navigation.prevEl.removeAttribute("hidden");
                }
              }}
              onSwiper={(swiper) => {
                swiper.slidePrev(0);
              }}
            >
              {maindata.data.map((movie) => (
                <SwiperSlide key={movie.admin_video_id}>
                  <Link
                    to={{
                      pathname: `/detail/${movie.admin_video_id}`,
                      movie: movie,
                    }}
                    onClick={this.scrollToTop}
                  >
                    <img
                      className="item  item5 sliderthumb-text swiper-lazy"
                      alt=""
                      src={movie.default_image}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </MediaQuery>

          <MediaQuery maxDeviceWidth={1224}>
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              navigation={false}
              slidesPerGroup={2}
              speed={900}
              loop={maindata.length < 6 ? false : true}
              lazy={true}
              freeMode={true}
              allowTouchMove={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  slidesPerGroup: 4,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                  slidesPerGroup: 6,
                },
              }}
              modules={[Navigation, Lazy, FreeMode]}
              preloadImages={false}
              // onClick={(swiper) => alert(swiper.activeIndex)}
              onSlideChange={(swiper) => {
                if (swiper.navigation.prevEl) {
                  swiper.isBeginning
                    ? swiper.navigation.prevEl.setAttribute("hidden", true)
                    : swiper.navigation.prevEl.removeAttribute("hidden");
                }
              }}
              onSwiper={(swiper) => {
                swiper.slidePrev(0);
              }}
            >
              {maindata.data.map((movie) => (
                <SwiperSlide key={movie.admin_video_id}>
                  <div className="dandash"></div>
                  <Link
                    to={{
                      pathname: `/detail/${movie.admin_video_id}`,
                      movie: movie,
                    }}
                    onClick={this.scrollToTop}
                  >
                    <img
                      className="item  item5 sliderthumb-text swiper-lazy"
                      alt=""
                      src={movie.default_image}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </MediaQuery>

          {/* } */}
        </div>
      </React.Fragment>
    );
  }
  render() {
    const movie = { ...this.props.movie };

    const styles = {
      containerStyle: {
        backgroundImage: `url(${
          this.state.backImageUrl
            ? this.state.backImageUrl[this.state.index]
            : movie.default_image
        })`,
        // opacity: this.state.loading ? 0 : 1,
        // transition: "opacity, 2s ease-in-out",
        transition: "background 1s linear",
      },
      dark: {
        background: "#00000047",
        width: "100%",
        height: "100%",
        zIndex: 2,
        position: "absolute",
      },
    };

    const {
      loadingFirst,
      videoDetailsFirst,
      videoDetailsSecond,
      loadingSecond,
      loadingSuggestion,
      suggestion,
    } = this.state;

    const { dark } = styles;
    const { containerStyle } = styles;
    return (
      <>
        {!loadingSecond ? (
          <>
            <div
              className="banner_details"
              style={{
                backgroundImage: `url(${videoDetailsFirst.default_image})`,
              }}
            ></div>
            <div className="mb-3 movie-content container">
              <div className="movie-content__poster">
                <div
                  className="movie-content__poster__img"
                  style={{
                    backgroundImage: `url(${videoDetailsFirst.mobile_image})`,
                  }}
                ></div>
              </div>
              <div className="movie-content__info">
                {/* <div className="movie_title">{videoDetailsFirst.title}</div> */}
                <VideoOverView
                  videoDetailsFirst={videoDetailsFirst}
                  trailer={videoDetailsSecond.trailer_section}
                  genreVideos={videoDetailsSecond.genre_videos}
                  genres={videoDetailsSecond.genres}
                  isSeries={videoDetailsSecond.is_series}
                />
              </div>
            </div>
            <div className="details_second_section">
              {videoDetailsSecond.is_series === 1 ? (
                <VideoEpisode
                  genreVideos={videoDetailsSecond.genre_videos}
                  genres={videoDetailsSecond.genres}
                />
              ) : (
                this.renderSuggestionList(videoDetailsSecond.suggestion_videos)
              )}
            </div>
          </>
        ) : (
          <ShimmerContentBlock
            title
            text
            cta
            thumbnailWidth={370}
            thumbnailHeight={370}
          />
        )}
      </>
      // <div className="content">
      //   <div style={dark}></div>{" "}
      //   <div className="content__background">
      //     <div className="content__background__shadow" />
      //     <div
      //       className="content__background__image"
      //       style={containerStyle}
      //       // style={{
      //       //     backgroundImage: `url(${movie.default_image})`
      //       // }}
      //     />
      //   </div>
      //   <div className="content__area">
      //     <div className="content__area__container">
      //       {/*
      //         <div className="content__title">{movie.title}</div>
      //         <div className="content__description">
      //           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
      //           et euismod ligula. Morbi mattis pretium eros, ut mollis leo tempus
      //           eget. Sed in dui ac ipsum feugiat ultricies. Phasellus vestibulum enim
      //           quis quam congue, non fringilla orci placerat. Praesent sollicitudin
      //         </div>
      //       */}
      //     </div>
      //     <div className="slider-content-tabcontent">
      //       <div className="tab-content">
      //         {loadingSecond ? (
      //           <ContentLoader />
      //         ) : (
      //           <div
      //             className={classNames("tab-pane", {
      //               active: this.state.nav == "overview",
      //               fade: this.state.nav != "overview",
      //             })}
      //           >
      //             <VideoOverView
      //               videoDetailsFirst={videoDetailsFirst}
      //               trailer={videoDetailsSecond.trailer_section}
      //               genreVideos={videoDetailsSecond.genre_videos}
      //               genres={videoDetailsSecond.genres}
      //               isSeries={videoDetailsSecond.is_series}
      //             />
      //           </div>
      //         )}

      //         {loadingSecond ? (
      //           ""
      //         ) : (
      //           <div
      //             className={classNames("tab-pane", {
      //               active: this.state.nav == "episodes",
      //               fade: this.state.nav != "episodes",
      //             })}
      //           >
      //             {/* <VideoEpisode
      //               genreVideos={videoDetailsSecond.genre_videos}
      //               genres={videoDetailsSecond.genres}
      //             /> */}
      //           </div>
      //         )}

      //         {loadingSecond ? (
      //           ""
      //         ) : (
      //           <div
      //             className={classNames("tab-pane", {
      //               active: this.state.nav == "trailers",
      //               fade: this.state.nav != "trailers",
      //             })}
      //           >
      //             <VideoTrailer trailer={videoDetailsSecond.trailer_section} />
      //           </div>
      //         )}

      //         <div
      //           className={classNames("tab-pane", {
      //             active: this.state.nav == "related",
      //             fade: this.state.nav != "related",
      //           })}
      //         >
      //           {loadingSuggestion ? (
      //             ""
      //           ) : (
      //             <VideoMoreLikeThis suggestion={suggestion} />
      //           )}
      //         </div>

      //         {loadingFirst ? (
      //           ""
      //         ) : (
      //           <div
      //             className={classNames("tab-pane", {
      //               active: this.state.nav == "details",
      //               fade: this.state.nav != "details",
      //             })}
      //           >
      //             <VideoDetails videoDetailsFirst={videoDetailsFirst} />
      //           </div>
      //         )}
      //       </div>
      //     </div>
      //     <div className="slider-content-tabsec">
      //       <ul className="nav nav-pills" role="tablist">
      //         <li className="nav-item">
      //           <a
      //             className={classNames("nav-link", {
      //               active: this.state.nav == "overview",
      //             })}
      //             onClick={(event) => this.navToggle("overview", event)}
      //             href="#"
      //           >
      //             overview
      //           </a>
      //         </li>
      //         {loadingFirst ? (
      //           ""
      //         ) : videoDetailsFirst.is_series ? (
      //           <li className="nav-item">
      //             <a
      //               className={classNames("nav-link", {
      //                 active: this.state.nav == "episodes",
      //               })}
      //               onClick={(event) => this.navToggle("episodes", event)}
      //               href="#"
      //             >
      //               episodes
      //             </a>
      //           </li>
      //         ) : (
      //           ""
      //         )}

      //         {loadingSecond
      //           ? ""
      //           : videoDetailsSecond.trailer_section.length && (
      //               <li className="nav-item">
      //                 <a
      //                   className={classNames("nav-link", {
      //                     active: this.state.nav == "trailers",
      //                   })}
      //                   onClick={(event) => this.navToggle("trailers", event)}
      //                   href="#"
      //                 >
      //                   Trailer
      //                 </a>
      //               </li>
      //             )}

      //         <li className="nav-item">
      //           <a
      //             className={classNames("nav-link", {
      //               active: this.state.nav == "related",
      //             })}
      //             onClick={(event) => this.navToggle("related", event)}
      //             href="#"
      //           >
      //             more like this
      //           </a>
      //         </li>
      //       </ul>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default VideoDetailsPage;
