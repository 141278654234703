import React, { Component } from "react";
import { translate, getLanguage } from "react-multi-lang";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import ContentRow from "../George/ContentRow";

import api from "../../Environment";
import Slider from "../NewUI/MainSlider";

//check mobile Devices
import MediaQuery from 'react-responsive'

class AboutPage extends Component {
    state = {
        HomeSettings: [],
        loading: true,
         maindata: null,
        errorHandle: 0,
        loadingContents:true,
    };
    componentDidMount() {

        this.fetchConfig();
    }

    async fetchConfig() {
        const response = await fetch(apiConstants.homeSettingsUrl);

        const homeResonse = await response.json();

        this.setState({
            loading: false,
            HomeSettings: homeResonse.data,
        });

    }

    render() {
        const { loading } = this.state;

        return (
            <div>                
                {loading ? (
                    <div>
                        
                    </div>
                ) : (
                        
                        <div>
                            <div className="g-landing-page-header">
                    <div className="g-logo-div">
                        <Link to="/">
                                <img
                                    src={configuration.get("configData.site_logo")}
                                    className="site-logo "
                                    alt="site_logo"
                                />
                        </Link>
                    </div>
                    
                
                                                                                    
                    <ul className="g-header-menu-list">
                      <div className="g-hover-img"><li><a href="/about-us">about</a></li></div>                                       
                        <div className="g-hover-img"><li><a href="#">Collection</a></li></div>
                        <div className="g-hover-img"><li><a href="#">BLOG</a></li></div>
                        <div className="g-hover-img"><li><a href="/contact-us">Contact</a></li></div>
                        <li><p>|</p></li>
                        <div className="g-hover-img"><li><a href="#">Login</a></li></div>
                        <div className="g-hover-img"><li><a href="#">sign up</a></li></div>
                        <li><Link to="/login" className="btn btn-danger g-btn-header">Try 7 days free</Link></li>			                                	                                                                                                
                    </ul> 
                                        
                                   
                    {/* <Link to="/login" className="btn btn-danger signin">{t("signin")}</Link> */}
                </div>
                        <div className="landing-banner-sec">
                            <MediaQuery minDeviceWidth={1224}>
                                <img
                                    className="about-banner-img"
                                    // src={HomeSettings.home_page_bg_image}
                                        srcSet="assets/img/about/about-us-bg-image.jpg 1000w,
                                    // assets/img/banner_medium.jpg 1500w,
                                    // assets/img/banner_large.jpg 1800w"
                                    alt={configuration.get("configData.site_name")}
                                />
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={1224}>
                                <img
                                    className="about-banner-img"
                                    // src={HomeSettings.home_page_bg_image}
                                        srcSet="assets/img/abput/about-us-bg-image.png 1000w,
                                    // assets/img/header-bg-image.png 1500w,
                                    // assets/img/header-bg-image.png 1800w"
                                    alt={configuration.get("configData.site_name")}
                                />

                            </MediaQuery>
                                

                            <div className="banner-black-overlay">
                                <div className="img_container">
                                    <div className="text-center about-container-text">
                                        <h1 className="benner-text-head langing-about-backimage">About Us </h1>                                  
                                        <h6 className="g-about-h6">HORRORMAX is the streaming service built for the true lover of all things horror. With over a THOUSAND films to choose from and new content added weekly, look no further than HORRORMAX for all the macabre films that will keep you up at night. All of your favorite horror niches, all in one heart stopping place - HORRORMAX!</h6>
                                        <div className="g-about-welcome">
                                            <h5>Welcome to </h5>
                                            <Link to="/">
                                                <img
                                                    src={configuration.get("configData.site_logo")}
                                                    className="about-logo "
                                                    alt="site_logo"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                        </div>

                                <div className="main main_back">
                                    <div className="tab-module-wrapper g-about-main">
                                        <div className="text-center g-about-text-center">
                                            <h1>It’s Time To Face Your Fears</h1>

                                            <p className="g-about-p-text-center">Shudder has the largest human-curated selection of high-quality, spine-tingling, and provocative films, TV series, and originals– there’s always something new and unexpected for Shudder members to explore.</p>
                                            {/* <p className="g-text-center-s">in all niches of the horror genre and new content added weekly, look no further than HORRORMAX.</p> */}
                                        </div>
                                    

                                        <div>
                                            <div className="row g-about-row">
                                                <div className="col-3">
                                                <img className="g-about-poster" srcSet="assets/img/horrormax-banner-a-wich-story.jpg" alt="" />
                                                <h5>EXCLUSIVE MOVIES</h5>
                                                </div>
                                                <div className="col-3">
                                                <img className="g-about-poster" srcSet="assets/img/horrormax-banner-carnivale-creepshow.jpg" alt="" />
                                                <h5>ORIGINAL SERIES</h5>
                                                </div>
                                                <div className="col-3">
                                                <img className="g-about-poster" srcSet="assets/img/horrormax-banner-world-w4r.jpg" alt="" />
                                                <h5>ESSENTIAL CLASSICS</h5>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                </div>
                            



                            <div className="main main_back g-main-background">
                                <div className="tab-module-wrapper">
                                    <div className="text-center g-about-text-center">
                                        <h1>Anywhere, Anytime.</h1>
                                         <p className="g-about-p-text-center">Experience more Shudder by connecting your account to all your favorite streaming devices. Switch devices mid-stream without losing your place.</p>
                                    </div>
                                    <div className="row g-s-row g-about-s-row">
                                        <div className="column g-column-text g-about-column">
                                            <div className="g-about-apps">
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-amazon.png" alt="Tv"/>
                                                </div>
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-apple.png" alt="Tv"/>
                                                </div>
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-google.png" alt="Tv"/>
                                                </div>
                                            </div>
                                            <div className="g-about-apps">
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-xbox.png" alt="Tv"/>
                                                </div>
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/about-roku.png" alt="Tv"/>
                                                </div>
                                                <div className="devices-img g-about-apps-img">
                                                    <img src="assets/img/about/playstation.png" alt="Tv"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column g-column-img">
                                            <div className="devices-img">
                                                        <img
                                                            src="assets/img/about/devices-about-us.png"
                                                            alt="Tv"
                                                        />
                                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                )}
            </div>
        );
    }
}

export default translate(AboutPage);
